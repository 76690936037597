<template>
    <div class="errPage-container">
        <el-button icon="el-icon-arrow-left" class="pan-back-btn" @click="back">返回</el-button>
        <el-row>
            <el-col :span="12">
                <h1 class="text-jumbo">Oops!</h1>
                <h2>你没有权限访问该页面</h2>
                <h6>如有问题请联系管理员</h6>
            </el-col>
            <el-col :span="12">
                <h1 class="text-jumbo text-ginormous">4 0 3</h1>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: '403',
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="scss" scoped>
.errPage-container {
    width: 800px;
    max-width: 100%;
    margin: 100px auto;
    .pan-back-btn {
        background: #008489;
        color: #fff;
        border: none !important;
    }
    .pan-gif {
        margin: 0 auto;
        display: block;
    }
    .pan-img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    .text-jumbo {
        font-size: 60px;
        font-weight: 700;
        color: #484848;
    }
    .text-jumbo.text-ginormous {
        color: #008489;
        font-size: 72px;
    }
    .list-unstyled {
        font-size: 14px;
        li {
            padding-bottom: 5px;
        }
        a {
            color: #008489;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    h1 {
        margin: 0.63em 0;
    }
    h2 {
        margin: 0.83em 0;
    }
    h6 {
        margin: 2.33em 0;
    }
}
</style>
